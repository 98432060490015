import React from 'react'
import { css } from '@emotion/core'

const buttonCss = (theme, size) => css`
  background-color: ${theme.colors.primary};
  color: #ffffff;
  border-color: ${theme.colors.primary};
  user-select: none;
  text-decoration: none;
  font-size: 14px;
  min-width: 36px;
  min-height: 36px;
  transition-property: opacity, border, color, background-color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  border-width: 1px;
  border-style: solid;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  display: inline-flex;
  line-height: 1.5;
  position: relative;
  padding: ${size === 'small' ? '6px 16px' : '10px 20px'};
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #4379d6;
    outline: 0;
  }
`

const Button = ({ children, size, ...rest }) => {
  return (
    <button type="button" css={theme => buttonCss(theme, size)} {...rest}>
      {children}
    </button>
  )
}

export default Button
