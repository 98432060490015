import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import Layout from '../components/layout'
import SEO from '../components/seo'

import heroImg from '../images/homepage-background1.jpg'
import quickNotesIcon from '../images/quick-notes-icon.png'
import meetIcon from '../images/google-hangouts-meet-icon.png'
import slackIcon from '../images/slack-icon.png'
import msIcon from '../images/ms-icon.png'
import chatsManagerIcon from '../images/chats-manager-icon.png'

import Button from '../components/button'

const heroCss = css`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 80px;

  @media screen and (min-width: 768px) {
    height: 400px;
  }
`

const heroInfoCss = css`
  flex-grow: 1;
  width: 50%;
`

const heroImageCss = css`
  display: none;
  justify-content: flex-end;
  flex-grow: 1;
  width: 50%;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`

const brandCss = theme => css`
  color: ${theme.colors.primary};
  font-weight: 700;
`

const headerCss = theme => css`
  color: ${theme.colors.primaryDark};
`

const heroTaglineCss = theme => css`
  margin-top: 32px;
  color: ${theme.colors.black};
`

const exploreCss = css``

const exploreHeaderCss = theme => css`
  color: ${theme.colors.primaryDark};
`

const appCardsCss = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const appCardCss = draft => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  cursor: ${draft ? 'not-allowed' : 'initial'};
  width: 100%;
  opacity: ${draft ? 0.5 : 1};

  &:hover {
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 768px) {
    width: 300px;
  }
`

const appCardLogoCss = css`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;

  > img {
    width: 100%;
  }
`

const appCardNameCss = theme => css`
  font-weight: 600;
  color: ${theme.colors.primaryDark};
  margin-bottom: 10px;
  font-size: 18px;
`

const appCardDescriptionCss = css`
  font-size: 14px;
  height: 70px;
  margin-bottom: 20px;
`

const buttonsCss = css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const tutorialCss = theme => css`
  color: ${theme.colors.primaryDark};
`

const apps = [
  {
    name: 'Quick Notes',
    icon: quickNotesIcon,
    description:
      'Take notes about recurring Customers or possible leads. Collaborate with other Agents',
    url: 'https://www.livechatinc.com/marketplace/apps/quick-notes/',
    tutorialUrl: '/apps/quick-notes/',
  },
  {
    name: 'Google Hangouts Meet',
    icon: meetIcon,
    description:
      'Jump on a video call right from the chat and investigate the problems in detail',
    url: 'https://www.livechatinc.com/marketplace/apps/google-hangouts-meet/',
    tutorialUrl: '/apps/google-hangouts-meet/',
  },
  {
    name: 'Slack for LiveChat',
    icon: slackIcon,
    description:
      'Enjoy the power of your favorite software working seamlessly together',
    tutorialUrl: '/apps/slack-for-livechat/',
  },
  {
    name: 'Microsoft Dynamics Cases',
    icon: msIcon,
    description: 'Manage contacts and cases directly from LiveChat',
    tutorialUrl: '/apps/microsoft-dynamics-cases/',
  },
  {
    name: 'Chats Manager',
    icon: chatsManagerIcon,
    description:
      'Effortlessly manage and monitor multiple live chats in real-time, boosting team efficiency and improving customer support',
    tutorialUrl: '/apps/chats-manager/',
  },
]

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

const IndexPage = () => {
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  return (
    <Layout>
      <SEO title="Home" />
      <div css={heroCss}>
        <div css={heroInfoCss}>
          <div css={brandCss}>Sikora Works</div>

          <h1 css={headerCss}>We are making things easier</h1>

          <p css={heroTaglineCss}>
            At Sikora Works we are providing integrations with LiveChat to help
            with your daily workflow.
          </p>

          <div>
            <Button onClick={executeScroll}>See our apps</Button>
          </div>
        </div>

        <div css={heroImageCss}>
          <div
            css={css`
              background-color: #276ff1;
              width: 130px;
              height: 70px;
              position: absolute;
              bottom: 70px;
              right: 200px;
            `}
          ></div>

          <div
            css={css`
              width: 0;
              height: 0;
              border-bottom: 70px solid #152e60;
              border-left: 70px solid transparent;
              position: absolute;
              bottom: 70px;
              right: 330px;
            `}
          ></div>

          <div
            css={css`
              background-color: #b6d1ff;
              width: 70px;
              height: 70px;
              position: absolute;
              bottom: 0px;
              right: 330px;
            `}
          ></div>
          <img
            src={heroImg}
            css={css`
              width: 330px;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
            `}
          />
        </div>
      </div>

      <div css={exploreCss}>
        <h2 css={exploreHeaderCss} ref={myRef}>
          Explore what we offer
        </h2>

        <div css={appCardsCss}>
          {apps.map((app, index) => (
            <div css={appCardCss(app.draft)} key={`app-${index}`}>
              <div css={appCardLogoCss}>
                <img src={app.icon} alt={app.name} />
              </div>
              <div css={appCardNameCss}>{app.name}</div>
              <div css={appCardDescriptionCss}>{app.description}</div>

              {!app.draft && (
                <div css={buttonsCss}>
                  <a href={app.url} target="_blank" rel="noopener noreferrer">
                    <Button size="small">See in LiveChat</Button>
                  </a>

                  <Link to={app.tutorialUrl} css={tutorialCss}>
                    Tutorial
                  </Link>
                </div>
              )}

              {app.draft && <div css={buttonsCss}>Coming soon</div>}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
